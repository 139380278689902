<!-- 用户登录 -->
<template>
  <div class="login">
    <div class="title">
      <div>请输入您的账号与密码进行账号注销操作。<br />账号注销后，平台将删除有关您的所有数据。<br />请您一定谨慎操作！</div>
    </div>

    <!-- 登录框 -->
    <div class="login-box">
      <van-form>
        <van-field
            v-model="login_user.mobile"
            center
            clearable
            name="mobile"
            placeholder="请输入登录账号"
            label="登录账号"
            label-width="70"
            @keyup="changeStatus"
        >
        </van-field>

        <van-field
            v-model="login_user.password"
            type="password"
            center
            clearable
            name="password"
            placeholder="请输入登录密码"
            label="登录密码"
            label-width="70"
            @keyup="changeStatus"
        />

        <van-button
            block
            type="info"
            color="#0EBE7E"
            native-type="submit"
            :disabled="isDisabled"
            class="submit-btn"
            @click="submit"
        >
          注销账号
        </van-button>
      </van-form>
    </div>
  </div>
</template>

<script>
import {cancel} from '@/api/user'

export default {
  data() {
    return {
      isDisabled: true,
      login_user: {
        mobile: undefined,
        password: undefined
      }
    }
  },
  methods: {
    changeStatus() {
      this.isDisabled = !(this.login_user.mobile && this.login_user.password);
    },
    submit() {
      cancel(this.login_user).then(() => {
        this.$toast.success("账号注销成功");
      })
    }
  }
}
</script>


<style lang="less" scoped>
.login {
  padding: 20px;
  box-sizing: border-box;
  height: 100%;
  background-color: #FFFFFF;

  .title {
    margin-top: 60px;
    font-size: 16px;
    font-weight: bold;
    text-align: center;
  }

  .login-box {
    margin-top: 50px;

    .van-field {
      padding-left: 15px;
      padding-right: 0;
    }

    .van-cell::after {
      left: 0;
    }

    .van-field__control {
      margin-left: 20px;
    }

    .submit-btn {
      margin-top: 50px;
      border-radius: 10px;
    }
  }
}
</style>
